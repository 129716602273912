import { Offcanvas } from '~bootstrap/dist/js/bootstrap.esm.min.js';
import applicationStyles from '/stylesheets/application.scss?inline';

let template = document.createElement('template');

template.innerHTML = /*html*/ `

  <style>
    ${applicationStyles}

    :host {
      display: flex;
      flex-direction: column;
      background-color: var(--gg-app-background);
      padding: .5rem;
      padding-top: 2.5rem;
    }

    .icon-action {
      min-width: 2rem;
      text-align: center;
      display: inline-block;
      margin: 0 .5rem;
      font-size: 2rem;
      border: 0;
      background: unset;
      text-decoration: none;
    }

    .icon-action .action-label {
      font-weight: bold;
      font-size: 1rem;
    }
    
    primary-actions {
      display: flex;
      justify-content: space-between;
    }
    
    primary-actions .app-logo {
      margin-left: .5rem;
      height: 2rem;
    }

    primary-actions i, secondary-actions a i {
      color: var(--gg-app-primary);
    }

    primary-actions .active i{
      color: var(--gg-app-primary-bright);
    }

    secondary-actions, #go-home {
      display: none;
    }

    a:active > i {
      color: var(--gg-app-primary-dark)!important;
    }

    :host([home]) secondary-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    :host([home]) #go-app-home,
    :host([home]) gg-autocomplete-btn {
      display: none;
    }

    #go-app-home {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    :host([home]) #go-home {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #gg-aside {
      max-width: 85%!important;
      overflow-y: scroll!important;
    }

    #gg-aside a {
      color: var(--gg-app-label);
      text-decoration: none;
    }

    .aside-icons {
      display: inline-block;
      text-align: center;
      color: var(--gg-app-primary);
      min-width: 2rem;
    }

    #gg-aside nav {
      padding: 1rem;
    }

    .nav-heading {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      display: block;
    }

    .nav-label {
      font-weight: 700;
    }

    .hamburger {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 0;
      margin-rigth: 0;
    }

    .btn-close {
      color: var(--gg-app-primary);
      border: 0;
      background: transparent;
      font-size: 1.5rem;
    }

    .offcanvas-header {
      padding-left: 0;
      padding-right: 0;
    }

    .offcanvas-header h5 {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--gg-app-label);
    }
  </style>

  <aside class="offcanvas offcanvas-start" id="gg-aside" tabindex="-1" aria-labelledby="offcanvasNavBarLabel">
    <nav>
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
        <button type="button" class='btn-close' id="close-sidebar" aria-label="Close navigation" aria-controls="gg-aside"><i class="fa-solid fa-circle-xmark" aria-hidden="true"></i></button>
      </div>
      <a class="nav-heading" href="/city-hall.html"><span class="aside-icons"><i class="fa-sharp fa-solid fa-building-columns"></i></span><span class="nav-label"> Open Government</span></a>
      <div class="list-group list-group-flush d-none">
        <a class="list-group-item" href="/city-hall.html#city-council">City Council</a>
        <a class="list-group-item" href="/city-hall.html#districts-portal">Districts Portal</a>
        <a class="list-group-item" href="/city-hall.html#departments">Departments</a>
        <a class="list-group-item" href="/city-hall.html#directors">Directors</a>
      </div>
      <a class="nav-heading d-flex justify-content-between" href="/services.html"><span><span class="aside-icons"><i class="fa-kit fa-city-emblem"></i></span><span class="nav-label"> Services</span></span><span><span class="nav-label">See All</span></span></a>
      <div class="list-group list-group-flush">
        <a class="list-group-item" href="https://ggcity.org/development-access" target="_blank">GG Ready</a>
        <a class="list-group-item" href="https://ggcity.org/report-issue" target="_blank">Report an Issue</a>
        <a class="list-group-item" href="https://secure.coplogic.com/rar/startrequest/200003333" target="_blank">Request a Copy of a Police Report</a>
        <a class="list-group-item" href="https://ggcity.org/water-billing" target="_blank">Water Billing</a>
        <a class="list-group-item" href="https://ggcity.org/building-and-safety/permits" target="_blank">Building Permits</a>
        <a class="list-group-item" href="https://ggcity.org/animal-care/found-animals" target="_blank">Found Animals Listing</a>
        <a class="list-group-item" href="https://ggcity.org/rv-parking" target="_blank">Recreational Vehicle Permit</a>
        <a class="list-group-item" href="https://ggcity.org/city-manager/district-election-mapping" target="_blank">Districts Mapping</a>
        <a class="list-group-item" href="https://ggcity.org/community-services" target="_blank">Parks and Recreation</a>
      </div>
      <a class="nav-heading" href="/news.html"><span class="aside-icons"><i class="fa-regular fa-newspaper"></i></span><span class="nav-label"> News</span></a>
      <a class="nav-heading" href="https://www.governmentjobs.com/careers/gardengrove"><span class="aside-icons"><i class="fa-kit fa-jobs"></i></span><span class="nav-label"> Job Opportunities</span></a>
      <a class="nav-heading" href="https://ggcity.org/bigg" target="_blank"><span class="aside-icons"><i class="fa-kit fa-bigg"></i></span><span class="nav-label"> Buy in Garden Grove</span></a>
      <a class="nav-heading" href="/residents.html"><span class="aside-icons"><i class="fa-solid fa-location-dot" aria-hidden="true"></i></span><span class="nav-label"> My Garden Grove</span></a>
    </nav>
  </aside>

  <primary-actions>
    <div id="go-home">
      <img src="/images/gg-logo-app.png" class='app-logo' alt="">
    </div>
    <span id="go-app-home">
      <a class="icon-action d-flex align-items-center mx-0" href="/" aria-label="Return to app home">
        <i class="fa-solid fa-chevron-left" style="color: var(--gg-app-primary-bright);" aria-hidden="true"></i>
        <span class="action-label" style="color: var(--gg-app-label);">Home</span>
      </a>
    </span>    
    <div class="actions">
      <a class='icon-action' href="tel://7147415000" aria-label="Call City Hall"><i class="fa-sharp fa-solid fa-phone-volume" aria-hidden="true"></i></a>  
      <a id="notifications" class='icon-action' href="/notifications.html" aria-label="Notifications List"><i class="fa-solid fa-bell" aria-hidden="true"></i></a>  
    </div>
  </primary-actions>
  <secondary-actions>
    <a class='icon-action hamburger' id="nav-expand" aria-label="Open Navigation" aria-controls="gg-aside" role="button" href="#"><i class="fa-solid fa-bars" aria-hidden="true"></i></a>
  </secondary-actions>
  `;

customElements.define('top-nav', class extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({
      mode: 'open'
    });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {

    //////////////////
    // Set Notifications Active
    //////////////////
    let activeLink = this.shadowRoot.getElementById('notifications')
    var current = location.pathname.split('/')[1];
    if (current !== '' && activeLink.getAttribute('href').indexOf(current) !== -1) {
      activeLink.classList.add('active');
      activeLink.setAttribute('aria-current', 'page');
    }

    ///////////////////
    // Secondary Nav
    ///////////////////
    if (this.hasAttribute('home')) {
      let expandNav = this.shadowRoot.querySelector('#nav-expand');
      let ggAside = this.shadowRoot.querySelector('#gg-aside');
      let closeBtn = ggAside.querySelector('#close-sidebar');

      let ggOffCanvas = new Offcanvas(ggAside);

      expandNav.addEventListener('click', () => ggOffCanvas.toggle());
      closeBtn.addEventListener('click', () => ggOffCanvas.hide());
    }
  }

  attributeChangedCallback(name) {
    // Only initialize search box when we're on the home page
    if (name === 'home') {
      let ggAutocomplete = document.createElement('gg-autocomplete')
      ggAutocomplete.setAttribute('aria-label', 'Search City of Garden Grove Website');

      this.shadowRoot.querySelector('secondary-actions')
        .appendChild(ggAutocomplete);
    }
  }

  static get observedAttributes() {
    return ['home'];
  }
});
